import React, { useState } from "react";

function RouteMap() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseClick = () => {
    setIsFullScreen(false);
  };

  const handleOverlayClick = (e) => {
    // Prevent closing when clicking on the image itself
    if (e.target.tagName !== "IMG") {
      handleCloseClick();
    }
  };

  return (
    <div className="mt-10 md:mt-16">
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
        <h3 className="w-full text-[#74613C] text-2xl md:text-4xl md:py-10 font-merriweather text-center p-3">
          ROUTE MAP
        </h3>
        <img
          className="w-full md:w-[50vw] h-80 cursor-pointer mt-4 md:mt-0"
          src="https://indusvalleymukteshwar.s3.eu-north-1.amazonaws.com/route-map.png"
          alt="Route Map"
          onClick={handleImageClick}
        />
      </div>

      {isFullScreen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50"
          onClick={handleOverlayClick}
        >
          <button
            className="absolute top-4 right-4 text-white text-3xl"
            onClick={handleCloseClick}
          >
            &times;
          </button>
          <img
            className="w-full h-full md:p-20 object-contain"
            src="https://indusvalleymukteshwar.s3.eu-north-1.amazonaws.com/route-map.png"
            alt="Route Map Full Screen"
          />
        </div>
      )}
    </div>
  );
}

export default RouteMap;
