import React, { useState, useEffect } from "react";
// import bg_project from '../Assets/bg_img/bg_project.jpg';
import line from "../Assets/bg_img/underline.png";

function DetailsContent() {
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col gap-3 md:gap-0 text-[#74613C]">
      <div>
        <h3 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:pt-10 font-merriweather text-center p-3">
          PROJECT OVERVIEW{" "}
        </h3>
        <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-xl font-merriweather text-center px-3">
          (PROPERTY FOR SALE IN NAINITAL)
        </h1>
      </div>

      <div className="flex flex-col gap-5 p-3 md:py-10 md:m-14 md:my-0 shadow-lg bg-white bg-opacity-30 font-merriweather">
        {/* style={{ backgroundImage: `url(${bg_project})`}} */}
        <p className="text-lg md:text-xl md:mx-16 px-3 font-semibold text-justify">
          Welcome to Indus Valley Mukhteshwar, a place where peace resides. This
          is a 10-acre private gated township in the lap of the Himalayas, a
          7-hour drive from Delhi and a 1.5-hour drive from Nainital, a perfect
          destination to invest in your dream house.
        </p>
        {showMore && (
          <>
            <p className="text-lg md:text-xl md:mx-16 px-3 text-justify">
              A tranquil getaway in the Kumaon foothills of Nainital, just 326
              km from New Delhi. This property is nestled at 7,358 ft above sea
              level. It offers breathtaking 360-degree views of the snow-covered
              Nanda Devi Himalayan range, surrounded by pine and deodar trees,
              valleys and fruit orchards.
            </p>
            <p className="text-lg md:text-xl md:mx-16 px-3">
              The Indus Valley is strategically located on the future road to
              Pithoragarh to{" "}
              <span className="font-semibold text-[#74613C]">
                {" "}
                Kailash Mansarovar
                <br /> "A RESIDENCE OF LORD SHIVA "
              </span>
            </p>
            <p className="text-lg md:text-xl md:mx-16 px-3">
              We offers various accommodation options, including plots, studios,
              and apartments, as well as customized cottages and premium
              semi-wooden villas,{" "}
              <span className="font-semibold text-[#74613C]">
                all with government approvals, including NOC from RERA.
              </span>{" "}
              Our amenities include a clubhouse, restaurant, parking, solar
              street lights, CCTV security, and a gated society along the state
              highway. Possession of cottages and apartments has been handed
              over to satisfied residents.
            </p>
            <p className="text-lg font-semibold md:text-xl md:mx-16 px-3">
              Our unique selling proposition (USP) is to deliver
              ready-to-move-in cottages, such as studio, 1-BHK, 2-BHK, 3-BHK,
              4-BHK, and tailor-made cottages of the customer's choice in just
              eight months. Moreover, if any resident wants to sell their
              property, Indus Valley buys back the property with good returns
              and benefits.
            </p>

            <p className="text-lg md:text-xl md:mx-16 px-3">
              For anyone looking for a property in Nainital, a cottage in
              Mukteshwar, a cottage for sale in Nainital, a property in
              Uttarakhand or cottages for sale in Mukteshwar, ideal place for
              second homes, gateway homes, vacation homes, or retirement homes
              and is the best gift that one can give to their loved ones.
            </p>
            <p className="text-lg md:text-xl md:mx-16 px-3">
              The Indus Valley is not just a property; it's an investment
              opportunity in the hills, offering the best returns up to 200
              times in the last decade with a track record of over 15 years.
            </p>
            <p className="text-lg md:text-xl font-semibold md:mx-16 px-3">
              Live amidst the beauty of the Himalayas!
            </p>
          </>
        )}

        {/* Read More Button (Mobile Only) */}
        {!showMore && window.innerWidth <= 768 ? (
          <button
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={toggleReadMore}
          >
            Read More
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default DetailsContent;
