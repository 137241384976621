import React from "react";

import { Link } from "react-router-dom";

import icon_facebook from "../Assets/icons/icon_facebook.png";
import icon_insta from "../Assets/icons/icon_insta.png";
import icon_yt from "../Assets/icons/icon_yt.png";
import icon_linkedin from "../Assets/icons/icon_linkdin.png";

const NewFooter = () => {
  const phoneNumber = "8510850101";

  return (
    <footer
      id="contact"
      style={{
        backgroundColor: "#f5faf5",
        paddingRight: 18,
        paddingLeft: 18,
        paddingBottom: 5,
      }}
    >
      <div
        style={{
          padding: 24,
          borderBottomWidth: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "60%",
          }}
          className="gap-8"
        >
          <a
            href="https://www.facebook.com/IndusValleyMukteshwar"
            target="_blank"
          >
            <img
              className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
              src={icon_facebook}
              alt="Facebook Link.."
            />
          </a>

          <a
            href="https://www.instagram.com/indusvalley.mukteshwar/?hl=en"
            target="_blank"
          >
            <img
              className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
              src={icon_insta}
              alt="Insta Link.."
            />
          </a>

          <a
            href="https://www.youtube.com/@indusvalleymukteshwar"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-10 h-fit p-1 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
              src={icon_yt}
              alt="Youtube Link.."
            />
          </a>
          <a
            href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFZYhyWERJFQgAAAZGdlEKoDO5UwFSCX_kMmBmkzRq_XfWMogORo6zxDysgkKq_9rMSzNho0Hy9xTKGoCSW00hxm6Kmi7Oyqtm47b2J3ha1Ox1zcRBYZaXE7wZVeiPB4zDKBE4=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F71418972%2Fadmin%2Ffeed%2Fposts%2F"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-10 h-fit p-1 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
              src={icon_linkedin}
              alt="Linkedin Link.."
            />
          </a>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="flex flex-col md:flex-row justify-evenly m-6">
          <div>
            <section>
              <p className="text-black text-l md:text-xl font-semibold">
                Registered Office
              </p>
              <p className="text-zinc-600 text-lg font-times font-thin ">
                The Shubham Group of Companies, <br />
                A-24, Okhla Industrial Area, Phase-2, Delhi, Pincode - 110020
              </p>
            </section>

            <section>
              <p className="text-black text-l md:text-xl font-semibold">
                Corporate Office
              </p>
              <p className="text-zinc-600 text-lg font-times font-thin  ">
                315, Block-B, Sector-105 Noida, Uttar Pradesh, Pincode - 201304
              </p>
            </section>

            <section>
              <p className="text-black text-l md:text-xl font-semibold">
                Contact Us
              </p>
              <div className="flex flex-col ">
                <a
                  className="text-zinc-600 text-lg  hover:text-blue-400 "
                  href="mailto:info@tsgoc.in"
                >
                  {" "}
                  info@theshubhamgroup.com
                </a>
                <a
                  className="text-zinc-700  text-lg  hover:black"
                  href={`tel:${phoneNumber}`}
                >
                  8510 850 101
                </a>
              </div>
            </section>
          </div>
          <div className="flex flex-col md:text-start w-full gap-3 md:max-w-[50%] md:ml-20">
            <p className="text-black text-l md:text-xl font-semibold">
              Site Address
            </p>
            <p className="text-zinc-600 text-lg font-times font-thin">
              Managher, Near Dhanachuli Bend,
              <br /> Village - Sunderkhal,
              <br /> Tehsil - Dhari,
              <br />
              Nainital, Uttrakhand.
              <br /> Pincode - 263132
            </p>
          </div>
        </div>

        <section style={{ alignContent: "center" }}>
          <img
            style={{ width: 300, height: 100, objectFit: "contain" }}
            src="https://indusvalleymukteshwar.s3.eu-north-1.amazonaws.com/theShubhamlogo.png"
            alt="Linkdin Link.."
          />
        </section>
      </div>

      <section class="copy-right">
        <p className="text-zinc-700  text-center text-sm font-times p-3 pt-0">
          Copyright © 2009 The Shubham Group All rights reserved.
        </p>
      </section>
    </footer>
  );
};

export default NewFooter;
