import React from "react";

const Banner = () => {
  return (
    <div className="relative">
      <video
        className="w-full h-auto xl:h-[850px] object-cover"
        autoPlay
        loop
        muted
        playsInline
        controls
        poster="/bg_video/bg5_video.jpg"
      >
        <source src="/bg_video/IndusvalleyBanner.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default Banner;
