import React, { useState, useEffect } from "react";
import line from "../Assets/bg_img/underline.png";

// import { Helmet } from "react-helmet";
import gal_img1 from "../Assets/Gallery/OuterView/ov1.webp";
import gal_img2 from "../Assets/Gallery/OuterView/ov2.webp";
import gal_img3 from "../Assets/Gallery/OuterView/ov3.webp";
import gal_img4 from "../Assets/Gallery/OuterView/ov4.webp";
import gal_img5 from "../Assets/Gallery/OuterView/ov5.webp";
import gal_img6 from "../Assets/Gallery/OuterView/ov6.webp";
import gal_img7 from "../Assets/Gallery/OuterView/ov7.webp";
import gal_img8 from "../Assets/Gallery/OuterView/ov8.jpg";
import gal_img9 from "../Assets/Gallery/OuterView/ov9.webp";
import gal_img10 from "../Assets/Gallery/OuterView/ov10.webp";
import gal_img11 from "../Assets/Gallery/OuterView/ov11.webp";
import gal_img12 from "../Assets/Gallery/OuterView/ov12.webp";
import gal_img13 from "../Assets/Gallery/OuterView/ov13.webp";
import gal_img14 from "../Assets/Gallery/OuterView/ov14.webp";
import gal_img15 from "../Assets/Gallery/OuterView/ov15.webp";
import gal_img16 from "../Assets/Gallery/OuterView/ov16.webp";
import gal_img17 from "../Assets/Gallery/OuterView/ov17.webp";
import gal_img18 from "../Assets/Gallery/OuterView/ov18.webp";
import gal_img19 from "../Assets/Gallery/OuterView/ov19.webp";
import gal_img20 from "../Assets/Gallery/OuterView/ov20.webp";
import gal_img21 from "../Assets/Gallery/OuterView/ov21.webp";
import gal_img22 from "../Assets/Gallery/OuterView/ov22.webp";
import gal_img23 from "../Assets/Gallery/OuterView/ov23.webp";
import gal_img24 from "../Assets/Gallery/OuterView/ov24.webp";
import gal_img25 from "../Assets/Gallery/OuterView/ov25.webp";
import gal_img26 from "../Assets/Gallery/OuterView/ov26.webp";
import gal_img27 from "../Assets/Gallery/OuterView/ov27.webp";
import gal_img28 from "../Assets/Gallery/OuterView/ov28.webp";
import gal_img29 from "../Assets/Gallery/OuterView/ov29.webp";
import gal_img30 from "../Assets/Gallery/OuterView/ov30.webp";
import gal_img31 from "../Assets/Gallery/OuterView/ov31.webp";
import gal_img32 from "../Assets/Gallery/OuterView/ov32.webp";
import gal_img33 from "../Assets/Gallery/OuterView/ov33.webp";
import gal_img34 from "../Assets/Gallery/OuterView/ov34.webp";
import gal_img35 from "../Assets/Gallery/OuterView/ov35.webp";
import gal_img36 from "../Assets/Gallery/OuterView/ov36.webp";
import gal_img37 from "../Assets/Gallery/OuterView/ov37.webp";
import gal_img38 from "../Assets/Gallery/OuterView/ov38.webp";
import gal_img39 from "../Assets/Gallery/OuterView/ov39.webp";
import gal_img40 from "../Assets/Gallery/OuterView/ov40.webp";

import GalleryIv from "./GalleryIv";
// import BannerP4 from "./BannerP4";
// import BookNowBanner from "./BookNowBanner";

const images = [
  { src: gal_img1, alt: "Cottage for stay in mukteshwar" },
  { src: gal_img2, alt: "Villa for stay in nainital" },
  { src: gal_img3, alt: "Gallery Image 3" },
  { src: gal_img4, alt: "Gallery Image 4" },
  { src: gal_img5, alt: "Gallery Image 5" },
  { src: gal_img6, alt: "Gallery Image 6" },
  { src: gal_img7, alt: "Gallery Image 7" },
  { src: gal_img8, alt: "Gallery Image 8" },
  { src: gal_img9, alt: "Gallery Image 9" },
  { src: gal_img10, alt: "Gallery Image 10" },
  { src: gal_img11, alt: "Gallery Image 11" },
  { src: gal_img12, alt: "Gallery Image 12" },
  { src: gal_img13, alt: "Gallery Image 13" },
  { src: gal_img14, alt: "Gallery Image 14" },
  { src: gal_img15, alt: "Gallery Image 15" },
  { src: gal_img16, alt: "Gallery Image 16" },
  { src: gal_img17, alt: "Gallery Image 17" },
  { src: gal_img18, alt: "Gallery Image 18" },
  { src: gal_img19, alt: "Gallery Image 19" },
  { src: gal_img20, alt: "Gallery Image 20" },
  { src: gal_img21, alt: "Gallery Image 21" },
  { src: gal_img22, alt: "Gallery Image 22" },
  { src: gal_img23, alt: "Gallery Image 23" },
  { src: gal_img24, alt: "Gallery Image 24" },
  { src: gal_img25, alt: "Gallery Image 25" },
  { src: gal_img26, alt: "Gallery Image 26" },
  { src: gal_img27, alt: "Gallery Image 27" },
  { src: gal_img28, alt: "Gallery Image 28" },
  { src: gal_img29, alt: "Gallery Image 29" },
  { src: gal_img30, alt: "Gallery Image 30" },
  { src: gal_img31, alt: "Gallery Image 31" },
  { src: gal_img32, alt: "Gallery Image 32" },
  { src: gal_img33, alt: "Gallery Image 33" },
  { src: gal_img34, alt: "Gallery Image 34" },
  { src: gal_img35, alt: "Gallery Image 35" },
  { src: gal_img36, alt: "Gallery Image 36" },
  { src: gal_img37, alt: "Gallery Image 37" },
  { src: gal_img38, alt: "Gallery Image 38" },
  { src: gal_img39, alt: "Gallery Image 39" },
  { src: gal_img40, alt: "Gallery Image 40" },

  // ... add more paths as needed
];

function Gallery2() {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openFullscreen = (image, index) => {
    setFullscreenImage(image.src);
    setCurrentIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
    setFullscreenImage(images[nextIndex].src);
  };

  const showPrevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
    setFullscreenImage(images[prevIndex].src);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (fullscreenImage) {
        if (event.key === "ArrowRight") {
          showNextImage();
        } else if (event.key === "ArrowLeft") {
          showPrevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [fullscreenImage, currentIndex]);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="w-fit text-[#74613C] mx-auto md:mx-0 text-2xl md:text-4xl font-merriweather text-center px-3 md:pl-10">
            GALLERY :
          </div>
          <div className="w-fit text-[#74613C] mx-auto md:mx-0 text-2xl md:text-4xl font-merriweather text-center px-3 md:pl-0">
            ORIGINAL SITE PICS
          </div>
        </div>
        <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-xl font-merriweather text-center px-3">
          (HOME IN UTTARAKHAND HILLS)
        </h1>
      </div>

      <section1 className="flex flex-col md:flex-row md:p-5 md:pb-0 lg:p-10 lg:pb-0 ">
        <video
          className=" lg:object-cover w-full md:p-5 md:pb-0 md:w-2/5"
          autoPlay={true}
          muted={true}
          loop={true}
          src="/bg_video/GalleryOv.mp4"
          type="video/mp4"
          // src="https://res.cloudinary.com/dqeka0ibm/video/upload/v1719482609/GalleryOv_g2382b.webm"
        />

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 p-5 pb-0 w-full md:w-3/5 bg-cover mx-auto">
          {images.map((image, index) => (
            <img
              key={index}
              className="shadow-md border border-black p-1 shadow-amber-50 gap-5 md:hover:shadow-[#74613C] md:hover:shadow-lg lg:hover:scale-110 md:duration-700 cursor-pointer"
              src={image.src}
              alt={image.alt}
              onClick={() => openFullscreen(image, index)}
            />
          ))}
        </div>
      </section1>

      <GalleryIv />

      {fullscreenImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center"
          onClick={closeFullscreen}
        >
          <button
            className="absolute bottom-52 left-20 text-white font-semibold text-2xl md:text-5xl font-merriweather m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:text-green-300 hover:font-bold focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              showPrevImage();
            }}
          >
            &#x2B9C;
          </button>

          <button
            className="absolute bottom-52 right-20 text-white font-semibold text-2xl md:text-5xl font-merriweather m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:text-green-300 hover:font-bold focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              showNextImage();
            }}
          >
            &#x2B9E;
          </button>
          <img
            src={fullscreenImage}
            alt="Fullscreen Img"
            className="max-h-full max-w-full cursor-pointer md:p-20 md:pt-32"
            onClick={(e) => e.stopPropagation()} // Prevent click event propagation
          />
        </div>
      )}
    </>
  );
}

export default Gallery2;
